// common.js ----------------------------
export const MUT_SHOW_ALERT = 'showAlert';
export const MUT_CLOSE_ALERT = 'closeAlert';
export const MUT_SHOW_CONFIRM = 'showConfirm';
export const MUT_SHOW_PROMPT = 'showPrompt';
export const MUT_CLOSE_PROMPT = 'closePrompt';
export const MUT_CLOSE_CONFIRM = 'closeConfirm';
export const MUT_START_LOADING = 'startLoading';
export const MUT_END_LOADING = 'endLoading';
export const MUT_SHOW_CONNECT = 'showConnect';
export const MUT_CLOSE_CONNECT = 'closeConnect';
export const MUT_SHOW_CONNECT_VIEW = 'showConnectView';
export const MUT_CLOSE_CONNECT_VIEW = 'closeConnectView';
export const MUT_SET_RETURN_ROUTE = 'setReturnRoute';
export const MUT_SET_COMMON_CODE_ITEMS = 'setCommonCodeItems';
export const MUT_SHOW_ADDRESS_MODAL = 'showAddressModal';

export const MUT_SET_MESSAGE_INTERVAL = 'setMessageInterval'
export const MUT_REMOVE_MESSAGE_INTERVAL = 'removeMessageInterval'
export const MUT_REMOVE_MESSAGE_COUNT = 'removeMessageCount'

export const MUT_SHOW_SEARCH_MODAL = 'showSearchModal'
export const MUT_CLOSE_SEARCH_MODAL = 'closeSearchModal'

export const MUT_SET_HEADER_STICKY = 'mutSetHeaderSticky';
export const MUT_SET_TABLE_MODAL_PAGING = 'setShowTableModalPaging';
export const MUT_SET_TABLE_MODAL_ITEMS = 'setShowTableModalItems';
export const MUT_SET_CAMPN_INFO_ITEM = 'setCampnInfoItem';
// ---------------------------------------

// auth.js  -----------------------------
export const MUT_SET_AUTH = 'setAuth';
export const MUT_SET_SESSION = 'setSession';
export const MUT_REMOVE_SESSION = 'removeSession';
export const MUT_SHOW_PROFILE_MODAL = 'mutShowProfileModal';
export const MUT_CLOSE_PROFILE_MODAL = 'mutCloseProfileModal';
export const MUT_SHOW_CREATOR_CHANGE_MODAL = 'mutShowCreatorChangeModal';
export const MUT_CLOSE_CREATOR_CHANGE_MODAL = 'mutCloseCreatorChangeModal';
export const MUT_SHOW_WHDWL_MODAL = 'mutShowWhdwlModal';
export const MUT_CLOSE_WHDWL_MODAL = 'mutCloseWhdwlModal';
export const MUT_SET_CHECK_ME_FUNC = 'mutSetCheckMeFunc';
export const MUT_SET_CHECK_ME_INFO = 'mutSetCheckMeInfo';
export const MUT_EXEC_CHECK_ME_CALL = 'mutExecCheckMeCall';
export const MUT_SET_AUTH_RES = 'setAuthRes';
// 약관재동의 팝업 노출 여부
export const MUT_SHOW_RETERMS_MODAL = 'mutShowRetermsModal';
export const MUT_CLOSE_RETERMS_MODAL = 'mutCloseRetermsModal';
// --------------------------------------------

// main.js  -----------------------------
export const MUT_SET_BANNERS = 'setBanners';
export const MUT_SET_SECTION_GROUPS = 'setSectionGroups';
// --------------------------------------------

// community.js  -----------------------------
export const MUT_SHOW_FOOTHOLD = 'showFoothold';
export const MUT_CLOSE_FOOTHOLD = 'closeFoothold';

export const MUT_SHOW_COMMUNITY = 'mutShowCommunity';
export const MUT_CLOSE_COMMUNITY = 'mutCloseCommunity';
// --------------------------------------------

// promotion.js  -----------------------------
export const MUT_SET_CATEGORY_LIST = 'setCategoryList';
// --------------------------------------------

// studio.js  -----------------------------
export const MUT_SHOW_RESERVATION_MODAL = 'setShowReservationModal';
export const MUT_CLOSE_RESERVATION_MODAL = 'setCloseReservationModal';
export const MUT_SET_RESERVATION_CATEGORIES = 'setReservationCategories';

export const MUT_SHOW_REVIEW_MODAL = 'setShowReviewModal';
export const MUT_CLOSE_REVIEW_MODAL = 'setCloseReviewModal';
// --------------------------------------------

// support.js  -----------------------------
export const MUT_SHOW_NOTICE_MODAL = 'setShowNoticeModal';
export const MUT_CLOSE_NOTICE_MODAL = 'setCloseNoticeModal';

export const MUT_SPRT_BIZ_TREE_MENU_LIST = 'setSprtBizTreeMenus';
export const MUT_SPRT_BIZ_TREE_MENU_VISIBLE = 'setSprtBizTreeMenuVisible';
// --------------------------------------------

// survey.js -----------------------------
export const MUT_SHOW_SURVEY = 'setShowSurvey';
export const MUT_CLOSE_SURVEY = 'setCloseSurvey';
// --------------------------------------------

// notice.js ----------------------------------
export const MUT_SHOW_COMMON_NOTICE_MODAL = 'mutShowCommonNoticeModal';
export const MUT_CLOSE_COMMON_NOTICE_MODAL = 'mutCloseCommonNoticeModal';
export const MUT_SHOW_EDU_NOTICE_MODAL = 'mutShowEduNoticeModal';
export const MUT_CLOSE_EDU_NOTICE_MODAL = 'mutCloseEduNoticeModal';
export const MUT_SHOW_SUPPORT_NOTICE_MODAL = 'mutShowSupportNoticeModal';
export const MUT_CLOSE_SUPPORT_NOTICE_MODAL = 'mutCloseSupportNoticeModal';
//---------------------------------------------

// video.js (20220803 hib) ----------------------------------
export const MUT_SHOW_COMMON_VIDEO_MODAL = 'mutShowCommonVideoModal';
export const MUT_CLOSE_COMMON_VIDEO_MODAL = 'mutCloseCommonVideoModal';
//---------------------------------------------

// broadcast.js  ----------------------------------
export const MUT_CLOSE_BROADCAST_CALENDAR_MODAL = 'mutCloseBroadcastCalendarModal';
export const MUT_SHOW_BROADCAST_CALENDAR_MODAL = 'mutShowBroadcastCalendarModal';
export const MUT_SHOW_BROADCAST_LIVE_MODAL = 'mutShowBroadcastLiveModal';
export const MUT_CLOSE_BROADCAST_LIVE_MODAL = 'mutCloseBroadcastLiveModal';
export const MUT_SHOW_BROADCAST_VOD_MODAL = 'mutShowBroadcastVodModal';
export const MUT_CLOSE_BROADCAST_VOD_MODAL = 'mutCloseBroadcastVodModal';
export const MUT_SET_RETURN_BRC_INFO_NO = 'mutSetReturnBrcInfoNo';
//---------------------------------------------


// vod.js -----------------------------
export const MUT_SET_AVATAR = 'mutSetAvatar';
// -------------------------------------------

// sentcont.js -----------------------------
export const MUT_SET_SENT_CONT_CTGR_ITEMS = 'mutSetSentContCtgrItems';
// -------------------------------------------

// manprd.js    -----------------------------
//--------------------------------------------