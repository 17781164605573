<template>
	<div class="layer-popup-container" v-show="alert.show || confirm.show || customPrompt.show">
		<transition name="gachi-fade">
			<div class="layer-popup-alert" v-if="customPrompt.show" :style="customPrompt.styleObj">
				<div class="popup-body">
					<p v-if="customPrompt.html" class="popup-text" v-html="customPrompt.title"></p>
					<p v-else class="popup-text">{{ customPrompt.title }}</p>
				</div>
				<div class="input-group">
					<input v-model="inputData" type="password" class="form-control form-control-lg" placeholder="비밀번호를 입력해주세요" required="" style="margin: 0 20px 20px 20px" autocomplete="off" @keyup.enter="clickPrompt" />
				</div>

				<div class="popup-buttons" @click="closePrompt">
					<button v-if="customPrompt.showCancelButton" type="button" class="button is-outlined">
						<span class="text">{{ customPrompt.cancelButtonText }}</span>
					</button>
					<button type="button" class="button is-primary" @click="clickPrompt">
						<span class="text text-secondary">{{ customPrompt.confirmButtonText }}</span>
					</button>
				</div>
			</div>
		</transition>
		<transition name="gachi-fade">
			<div class="layer-popup-alert" v-if="confirm.show" :style="confirm.styleObj">
				<header class="popup-header">
					<h3 v-if="confirm.header" class="header-title">{{ confirm.headerTitle }}</h3>
					<h3 v-else class="header-title">안내</h3>
					<button type="button" @click="closeConfirm"><i class="icon-popup-close"></i><span class="sr-only">창닫기</span></button>
				</header>
				<div class="popup-body">
					<p v-if="confirm.html" class="popup-text" v-html="confirm.title"></p>
					<p v-else class="popup-text">{{ confirm.title }}</p>
				</div>
				<div class="popup-buttons" @click="closeConfirm">
					<button v-if="confirm.showCancelButton" type="button" class="button is-outlined">
						<span class="text">{{ confirm.cancelButtonText }}</span>
					</button>
					<button type="button" class="button is-primary" @click="clickConfirm">
						<span class="text">{{ confirm.confirmButtonText }}</span>
					</button>
				</div>
			</div>
		</transition>
		<transition name="gachi-fade">
			<div class="layer-popup-alert" v-if="alert.show" :style="alert.styleObj">
				<div class="popup-body" :class="alert.bodyClass">
					<p v-if="alert.html" class="popup-title" v-html="alert.title"></p>
					<p v-else class="popup-title">{{ alert.title }}</p>

					<template v-if="alert.subText">
						<p v-if="alert.html" class="popup-subtext" v-html="alert.subText"></p>
						<p v-else class="popup-subtext">{{ alert.subText }}</p>
					</template>
				</div>
				<div class="popup-buttons">
					<button type="button" class="button is-primary" @click="clickAlert">
						{{ alert.confirmButtonText }}
					</button>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { MUT_CLOSE_ALERT, MUT_CLOSE_CONFIRM, MUT_CLOSE_PROMPT } from '../../store/_mut_consts';

export default {
	name: 'CommonAlert',
	props: {},
	computed: {
		...mapGetters('common', ['alert', 'confirm', 'customPrompt']),
	},
	data: () => ({
		inputData: '',
	}),
	mounted() {
		// this.$refs.focus.addEventListener('keyup',  (e) => {
		//   if (e.key === 'Enter') {
		//     if(this.alert.show && !this.alert.enterAble){
		//       this.clickAlert();
		//     }
		//   }
		// });
	},
	methods: {
		closePrompt() {
			this.inputData = '';
			this.$store.commit(`common/${MUT_CLOSE_PROMPT}`);
			if (this.customPrompt.nofunc instanceof Function) {
				setTimeout(() => {
					this.customPrompt.nofunc();
				}, 300);
			}
		},
		closeConfirm() {
			this.$store.commit(`common/${MUT_CLOSE_CONFIRM}`);
			if (this.confirm.nofunc instanceof Function) {
				setTimeout(() => {
					this.confirm.nofunc();
				}, 300);
			}
		},
		clickPrompt() {
			let param = this.inputData;
			this.inputData = '';
			this.$store.commit(`common/${MUT_CLOSE_PROMPT}`);
			if (this.customPrompt.yesfunc instanceof Function) {
				if (this.customPrompt.rightNow) {
					this.customPrompt.yesfunc(param);
				} else {
					setTimeout(() => {
						this.customPrompt.yesfunc(param);
					}, 300);
				}
			}
		},
		clickConfirm() {
			this.$store.commit(`common/${MUT_CLOSE_CONFIRM}`);
			if (this.confirm.yesfunc instanceof Function) {
				if (this.confirm.rightNow) {
					this.confirm.yesfunc();
				} else {
					setTimeout(() => {
						this.confirm.yesfunc();
					}, 300);
				}
			}
		},
		clickAlert() {
			this.$store.commit(`common/${MUT_CLOSE_ALERT}`);
			if (this.alert.yesfunc instanceof Function) this.alert.yesfunc();
		},
	},
};
</script>
