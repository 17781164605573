const menus = [
	{
		title: '동행축제',
		code: 'menu1',
		path: '/ko/info',
		isActive: false,
		isSubActive: false,
		children: [
			{
				title: '행사정보',
				path: '/ko/info',
				isTargetBlank: false,
			},
			// 임시로 주석요청 사용하지 않는 건 아님 - 현업 요청
			// {
			// 	title: '행사연혁',
			// 	path: '/ko/info/campnHistory',
			// 	isTargetBlank: false,
			// },
			{
				title: '홍보물',
				path: '/ko/promotion',
				isTargetBlank: false,
			},
		],
	},
	{
		title: '상생할인전',
		code: 'menu2',
		path: '/ko/exhibitionList',
		isActive: false,
	},
	{
		title: '온에어쇼핑',
		code: 'menu3',
		path: '/ko/onair',
		isActive: false,
	},
	{
		title: '동행제품',
		code: 'menu4',
		path: '/ko/products/category',
		isActive: false,
		isSubActive: false,
		children: [
			{
				title: '제품 카테고리',
				path: '/ko/products/category',
				isTargetBlank: false,
			},
			{
				title: '인기제품',
				path: '/ko/products/popular',
				isTargetBlank: false,
			},
		],
	},
	{
		title: '지역행사',
		code: 'menu5',
		path: '/ko/festival',
		isActive: false,
	},
	{
		title: '이벤트',
		code: 'menu6',
		path: '/ko/event/check',
		isActive: false,
		isSubActive: false,
		children: [
			{
				title: '출석체크',
				path: '/ko/event/check',
				isTargetBlank: false,
			},
			{
				title: '찜하기',
				path: '/ko/event/dibs',
				isTargetBlank: false,
			},
			{
				title: '구매후기',
				path: '/ko/event/sns',
				isTargetBlank: false,
			},
		],
	},
	{
		title: '동행뉴스',
		code: 'menu7',
		path: '/ko/news/list',
		isActive: false,
	},
];

export default menus;
