const menus = [
	{
		title: 'Event Guide',
		code: 'menu1',
		path: '/en/event',
		isActive: false,
	},
	{
		title: 'Featured Products',
		code: 'menu2',
		path: '/en/products',
		isActive: false,
	},
];

export default menus;
