<template>
	<div class="gachi" id="gachi" :class="{ 'gachi-main-background': isMain }">
		<nprogress-container />
		<skip-nav />

		<!-- s: header -->
		<main-header :open-aside-menu="menuActive" />
		<mobile-aside :is-non-active-menu="menuActive" :open-menu="isMenu" />
		<!-- e: header -->

		<!-- s: page-container -->
		<router-view />
		<!-- e: page-container -->

		<!-- s: footer -->
		<main-footer />
		<!-- e: footer -->

		<common-alert />
		<address-modal />
		<loading-modal v-if="isLoading" />
	</div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import SkipNav from './navigation/SkipNav';
import MainHeader from './header/MainHeader';
import MainFooter from './footer/MainFooter';
import { mapGetters } from 'vuex';
import { ACT_GET_SESSION, ACT_REMOVE_AUTH_TOKEN } from '../../store/_act_consts';
import { MUT_SET_SESSION } from '../../store/_mut_consts';
import { getItem, lengthCheck } from '../../assets/js/utils';
import CommonAlert from '../../components/common/CommonAlert';
import MobileAside from './navigation/MobileAside';
import AddressModal from '../../components/AddressModal';
import LoadingModal from '../../components/common/LoadingModal';

export default {
	name: 'MainLayout',
	components: {
		NprogressContainer,
		SkipNav,
		MobileAside,
		MainHeader,
		MainFooter,
		CommonAlert,
		AddressModal,
		LoadingModal,
	},
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile']),
		...mapGetters('common', ['isMobile', 'address', 'isLoading']),
		...mapGetters('video', ['video']),
		isMain() {
			return this.grayNames.includes(this.$route.name);
		},
	},
	data: () => ({
		isMenu: false,
		grayNames: ['Main'],
		msgCount: 0,
		items: [],
		currentYmd: '0',
		noticeItems: [],
		eduItems: [],
		supportItems: [],
	}),
	async created() {
		if (this.isAuth && !this.session.userPattnCd) {
			try {
				const res = await this.$store.dispatch(`auth/${ACT_GET_SESSION}`);
				if (lengthCheck(res)) {
					await this.$store.commit(`auth/${MUT_SET_SESSION}`, getItem(res));
					// 여기서 ==> 알람을 처리한다
					// this.setMessageReceiveCountInterval();

					// if (this.$route.name === 'Main') {
					// 	this.getSurveyList(true);
					// }
				} else {
					await this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
				}
			} catch (e) {
				// console.error(e);
				await this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
			}
		}
	},
	beforeDestroy() {},
	methods: {
		menuActive(menu) {
			this.isMenu = menu;
		},
	},
};
</script>
