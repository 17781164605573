<template>
	<div class="gachi" id="gachi" :class="{ 'gachi-main-background': isMain }">
		<nprogress-container />
		<skip-nav />

		<!-- s: header -->
		<main-header :open-aside-menu="menuActive" />
		<mobile-aside :is-non-active-menu="menuActive" :open-menu="isMenu" />
		<!-- e: header -->

		<!-- s: page-container -->
		<router-view />
		<!-- e: page-container -->

		<!-- s: footer -->
		<main-footer />
		<!-- e: footer -->

		<common-alert />
		<address-modal />
		<loading-modal v-if="isLoading" />
	</div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import SkipNav from './navigation/SkipNav';
import MainHeader from './header/EngMainHeader';
import MainFooter from './footer/EngMainFooter';
import { mapGetters } from 'vuex';
import CommonAlert from '../../components/common/CommonAlert';
import MobileAside from './navigation/EngMobileAside';
import AddressModal from '../../components/AddressModal';
import LoadingModal from '../../components/common/LoadingModal';

export default {
	name: 'MainLayout',
	components: {
		NprogressContainer,
		SkipNav,
		MobileAside,
		MainHeader,
		MainFooter,
		CommonAlert,
		AddressModal,
		LoadingModal,
	},
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile']),
		...mapGetters('common', ['isMobile', 'address', 'isLoading']),
		...mapGetters('video', ['video']),
		isMain() {
			return this.grayNames.includes(this.$route.name);
		},
	},
	data: () => ({
		isMenu: false,
		grayNames: ['Main'],
		msgCount: 0,
		items: [],
		currentYmd: '0',
		noticeItems: [],
		eduItems: [],
		supportItems: [],
	}),
	created() {},
	beforeDestroy() {},
	mounted() {
		document.documentElement.lang = 'en';
	},
	methods: {
		menuActive(menu) {
			this.isMenu = menu;
		},
	},
};
</script>
