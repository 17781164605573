import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from './jwt.service';
import store from '../../store';
import router from '../../router';
import {MUT_REMOVE_SESSION, MUT_SHOW_ALERT} from '../../store/_mut_consts';

/**
 * Service to call HTTP request via Axios
 */
const DOMAIN_URL = process.env['VUE_APP_DOMAIN_URL']

const setAxiosInterceptor = {
  request: (axios) => {
    axios.interceptors.request.use(function (request) {
      const headers = request.headers;
      if(JwtService.getToken()) headers['X-AUTH-TOKEN'] = JwtService.getToken();
      request.headers = headers;
      return request;
    }, function (error) {
      return Promise.reject(error);
    });
  },
  response: (axios) => {
    axios.interceptors.response.use(function (response) {
      if(response && response.data && response.data.status){
        /* AceCounter Log 사용 안함으로 주석
        if(response.config) {
          _PL(DOMAIN_URL + response.config.url)
        }
        */
        response.data.data.status = response.data.status;

        return response.data.data;
      }else{
        return response;
      }
    }, function (error) {
      return Promise.reject(error);
    });
  },
  lndResponse: (axios) => {
    axios.interceptors.response.use(function (response) {
      if(response && response.data){
        response.data.status = response.status;
        return response.data;
      }else{
        return response;
      }
    }, function (error) {
      return Promise.reject(error);
    });
  },
}


const ApiService = {
  init() {
    Vue.use(VueAxios, axios);

    this.apisHost = process.env['VUE_APP_API_HOST'];
    // Vue.axios.defaults.baseURL = this.apisHost;
    setAxiosInterceptor.request(Vue.axios);
    setAxiosInterceptor.response(Vue.axios);

    this.shopMallAxios = axios.create({
      baseURL: process.env['VUE_APP_SHOP_MALL_API_HOST']
    });

    setAxiosInterceptor.request(this.shopMallAxios);
    setAxiosInterceptor.lndResponse(this.shopMallAxios);
  },
  shopMallAxios: null,
  apisHost: '',
  query(resource, params, loginCheck) {
    return Vue.axios.get(resource, {params: params}).catch(error => {
      // console.error(`Gachi Error : ${error}`);
      if(loginCheck) removeSession(error);
    });
  },
  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param loginCheck
   * @returns {*}
   */
  get(resource, slug = '', loginCheck) {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.error(`Gachi Error : ${error}`);
      if(loginCheck) removeSession(error);
    });
  },
  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param loginCheck
   * @returns {*}
   */
  post(resource, params, loginCheck) {
    return Vue.axios.post(`${resource}`, params).catch(error => {
      // console.error(`Gachi Error : ${error}`);
      if(loginCheck) removeSession(error);
    });
  },
  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @param loginCheck
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params, loginCheck) {
    return Vue.axios.put(`${resource}`, params).catch(error => {
      // console.error(`Gachi Error : ${error}`);
      if(loginCheck) removeSession(error);
    });
  },
  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @param loginCheck
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params, loginCheck) {
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      // console.error(`Gachi Error : ${error}`);
      if(loginCheck) removeSession(error);
    });
  },
  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param slug
   * @param loginCheck
   * @param slug
   * @param loginCheck
   * @returns {*}
   */
  delete(resource, slug, loginCheck) {
    return Vue.axios.delete(`${resource}/${slug}`).catch(error => {
      // console.error(`Gachi Error : ${error}`);
      if(loginCheck) removeSession(error);
    });
  },
  upload(resource, fileList, isMulti){
    // const apisHost = this.apisHost;
    return new Promise((resolve, reject) => {
      const data = new FormData();
      if(isMulti){
        if(fileList && fileList.length > 0){
          for(let i = 0; i < fileList.length; i++){
            data.append("uploadFiles", fileList[i]);
          }
        }
      }else{
        if(fileList && fileList.length > 0){
          data.append( 'uploadFile', fileList[0]);
        }
      }
      const xhr = new XMLHttpRequest();
      xhr.timeout = 10 * 60 * 1000;
      // xhr.open("POST", `${this.apisHost}${resource}`, true);
      xhr.open("POST", `${resource}`, true);
      xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
      xhr.setRequestHeader('X-AUTH-TOKEN', JwtService.getToken());
      xhr.responseType = 'json';

      xhr.addEventListener( 'load', () => {
        const response = xhr.response;
        if (!response || response.error) {
          return reject(response && response.error ? response.error : 'Gachi Portal App Error');
        }else{
          return resolve(response);
        }
        //
        // }
      });

      xhr.send(data);
    });
  },
  download(resource, slug, params, fileName){
    const xhr = new XMLHttpRequest();
    // let url = `${this.apisHost}${resource}/${slug}`;
    let url = `${resource}/${slug}`;
    if(params !== null && params !== undefined){
      const keys = Object.keys(params);
      if(keys.length > 0){
        url += ('?'+ keys.map(key => `${key}=${params[key]}`).join('&'));
      }
    }

    xhr.open("GET", url, true);
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('X-AUTH-TOKEN', JwtService.getToken());
    xhr.responseType = "blob";
    xhr.onload =  (event) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(xhr.response);
      link.download = fileName;
      link.click();
    };
    xhr.send();
  }
};

const removeSession = (error) => {
  if(error.response && error.response.status === 403){
    store.commit(`auth/${MUT_REMOVE_SESSION}`);

    store.commit(`common/${MUT_SHOW_ALERT}`, {
      title: '회원가입 및 로그인이 필요합니다.',
      html: true,
      yesfunc: () => {router.push({name:'Login'});},
    });
  }
}

export default ApiService;
