import ApiService from '@/assets/js/api.service';

import {
    ACT_GET_AR_EVENT_LIST,
    ACT_UPDATE_AR_EVENT_INQ_TMS,
    ACT_GET_PRTC_CO_LIST,
} from '@/store/_act_consts';

const state = {
    PAGING: {
        hasMore: false,
        totalCount: 0,
        pageSize: 10,
        pageNo: 1,
    },
}

const getters = {
    PAGING(state) {
        return state.PAGING
    },
}

const mutations = {}

const actions = {
    [ACT_GET_AR_EVENT_LIST]({ }, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/dhfesta/arEvent/list', params).then(response => resolve(response))
        })
    },
    [ACT_UPDATE_AR_EVENT_INQ_TMS]({ }, params) {
        return new Promise(resolve => {
            ApiService.put(`/v1/app/dhfesta/arEvent/update/${params.arEventId}/inqTms`).then(response => resolve(response));
        });
    },
    [ACT_GET_PRTC_CO_LIST]({ }, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/dhfesta/prtcCo/list', params).then(response => resolve(response))
        })
    },
}

export const festival = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}