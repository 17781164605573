<template>
  <div class="layer-popup-container gachi-loading-layer" id="popup-load">
    <!-- [layer-popup-mini:type1] -->
    <span class="gachi-modal-loader"></span>
  </div>
</template>
<script>

export default {
  name: 'LoadingModal',
}
</script>
