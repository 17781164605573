import store from '../../store';
import {MUT_EXEC_CHECK_ME_CALL, MUT_SHOW_ALERT} from "../../store/_mut_consts";

const connectPlugin = {}
connectPlugin.install = function (Vue) {
    Vue.prototype.$_callInFunction = function(key, value) {
        if(value.result && value.result === -1) {
            store.commit(`common/${MUT_SHOW_ALERT}`, {
                title: '본인인증에 실패하였습니다. ' + (value.msg ? (' :' + value.msg) : '')
            });
        } else {
            const info = {key: key, value: value}
            store.commit(`auth/${MUT_EXEC_CHECK_ME_CALL}`, info)
        }
    }
}
export default connectPlugin
