import ApiService from '../../assets/js/api.service';
import {MUT_SET_BANNERS, MUT_SET_SECTION_GROUPS} from '../_mut_consts';
import {
    ACT_GET_BANNER_LIST,
    ACT_GET_EDU_BANNER_LIST,
    ACT_GET_SECTION_GROUP_LIST,
    ACT_GET_UTIL_SHORT_URL,
} from '../_act_consts';

const state = {
    banners: [],
    bannerExpired: 0,

    sectionGroups: [],
    sectionExpired: 0,
}

const getters =  {
    banners (state) {
        return state.banners;
    },
    bannerExpired (state) {
        return state.bannerExpired;
    },
    sectionGroups (state) {
        return state.sectionGroups;
    },
    sectionExpired (state) {
        return state.sectionExpired;
    }
}

const mutations = {
    [MUT_SET_BANNERS](state, banners){
        state.banners = banners;
        state.bannerExpired = new Date().getTime() + 1000 * 60 * 5 * 1000;
    },
    [MUT_SET_SECTION_GROUPS](state, sectionGroups){
        state.sectionGroups = sectionGroups;
        state.sectionExpired = new Date().getTime() + 1000 * 60 * 5 * 1000;
    },
}

const actions = {
    [ACT_GET_BANNER_LIST]({}){
        return new Promise(resolve => {
            ApiService.query('/v1/app/main/bannrs', {}).then(response => resolve(response));
        });
    },
    [ACT_GET_SECTION_GROUP_LIST](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/main/sect/grps', {expsrYn:'Y'}).then(response => resolve(response));
        });
    },
    [ACT_GET_EDU_BANNER_LIST](){
        return new Promise(resolve => {
            ApiService.query('/v1/app/main/edu/bannrs', {useYn:'Y'}).then(response => resolve(response));
        });
    },
    [ACT_GET_UTIL_SHORT_URL]({}, params){
        return new Promise(resolve => {
            ApiService.query('/v1/app/util/shortUrl', params).then(response => resolve(response));
        });
    }
}

export const main = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
