<template>
	<!--  <aside class="gachi-aside" :class="{'is-active' : isActive}" id="gachi-aside">-->
	<aside class="gachi-aside" :class="{ 'is-active': openMenu }" id="gachi-aside">
		<div class="aside-header page-component">
			<div class="header-logo">
				<router-link :to="{ name: 'Main' }" class="logo-link" @click.native="asideClose">
					<i class="logo"></i>
					<span class="sr-only">살맛나는 행복쇼핑 동행축제 2024</span>
				</router-link>
			</div>
			<button class="btn-aside-close" data-toggle="aside-close" @click="asideClose">
				<i class="icon-close">메뉴닫기</i>
			</button>
		</div>

		<div class="aside-body page-component">
			<nav class="aside-nav">
				<ul class="nav-list">
					<li v-for="(menu, idx) in menus" class="nav-item" :key="idx" :class="[{ 'is-active': menu.isActive && menu.children && menu.children.length }, { 'has-child': menu.children && menu.children.length }]" @click="menuToggle(idx, $event)">
						<a v-if="menu.path && !menu.children" :href="menu.path" class="nav-link" @click="asideClose" target="">
							<span class="nav-text" v-html="menu.title"></span>
						</a>
						<a v-else class="header-menu-link">
							<span class="nav-text" v-html="menu.title"></span>
						</a>
						<ul v-if="menu.children && menu.children.length > 0" class="nav-submenu-list" :data-height="`${menu.children.length * 38}`" :style="{ 'max-height': menu.isActive ? `${menu.children.length * 38}px` : '0px' }">
							<li v-for="(child, i) in menu.children" class="nav-submenu-item" :class="{ 'is-active': currentPath(child.path) }" :key="`sub-${idx}-${i}`">
								<a :href="child.path" class="nav-submenu-link" @click="asideClose">
									<span class="nav-submenu-text">{{ child.title }}</span>
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
		</div>
		<div class="aside-footer page-component">
			<ul class="display-flex">
				<li>
					<a href="https://portal.valuebuy.kr" target="_blank" title="새창열림">
						<i class="icon icon-logo-gachi"><icon name="logo-gachi" /></i>
						<span class="sr-only">가치삽시다</span>
					</a>
				</li>
				<!-- <li>
					<a href="/en" class="footer-util-link">ENG</a>
				</li> -->
			</ul>
		</div>
	</aside>
</template>

<script>
import menus from '@/assets/js/menus';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';
import { ACT_REMOVE_AUTH_TOKEN } from '@/store/_act_consts';
import { mapGetters } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
	},
	components: { Icon },
	name: 'MobileAside',
	props: {
		openMenu: Boolean,
		isNonActiveMenu: Function,
	},
	data: () => ({
		menus: menus,
		isMenu: false,
		marketUrl: `${process.env.VUE_APP_GACHI_MALL_HOST}`,
	}),
	methods: {
		asideClose() {
			// 메뉴 닫기
			this.isNonActiveMenu(false);
		},
		menuToggle(idx, e) {
			// 옵션
			if (e.target.classList[0] !== 'nav-submenu-text') {
				this.menus[idx].isActive = !this.menus[idx].isActive;
			}
		},
		currentPath(path) {
			return this.$route.path === path;
		},
		logout() {
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: `로그아웃을 하시겠습니까?`,
				showCancelButton: true,
				yesfunc: () => {
					this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
					if (this.$route.name === 'MyPage' || this.$route.name === 'MyPage2') {
						//마이페이지(신규) 에서도 로그아웃 시 로그인 화면으로 이동 추가
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					}
				},
			});
		},
	},
};
</script>
