<template>
	<footer class="footer eng">
		<div class="footer-wrap">
			<div class="footer-privacy">
				<div class="container">
					<router-link to="/ko/privacy-policy">Privacy Policy</router-link>
				</div>
			</div>
			<div class="footer-info">
				<div class="container">
					<div class="footer-info-lt">
						<div class="footer-info-logo">
							<ul>
								<li class="footer-info-logo1">
									<i class="icon"></i>
									<span class="sr-only">중소벤처기업부</span>
								</li>
								<li class="footer-info-logo2">
									<i class="icon"></i>
									<span class="sr-only">중소기업유통센터</span>
								</li>
								<li class="footer-info-logo3">
									<i class="icon"></i>
									<span class="sr-only">소상공인시장진흥공단</span>
								</li>
							</ul>
						</div>
						<div class="footer-info-txt">
							<dl>
								<dt>Inquiries for Participating Companies</dt>
								<dd>02-6678-9881, 9882, 9883, 9885, 9886</dd>
							</dl>
							<dl>
								<dt>Inquiries for Promotional Materials and Events</dt>
								<dd>02-6951-0624</dd>
							</dl>
							<dl>
								<dt>Available Hours</dt>
								<dd>Weekdays 10 AM - 5 PM (Lunch Break: 12 PM - 1 PM)</dd>
							</dl>
						</div>
					</div>
					<div class="footer-info-rt">
						<h5>Sponsor</h5>
						<div class="footer-info-rt__img"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="quick-btn fcc" id="top_btn2"><a></a></div>
	</footer>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
	name: 'MainFooter',
	components: { Icon },
	data: () => ({}),
	computed: {},
};
</script>
