import ApiService from '@/assets/js/api.service';

import {
    ACT_GET_ONAIR_BRC_LIST,
} from '@/store/_act_consts';

const state = {
    STATUS: {
        ON_AIR: "ON_AIR",
        SOON: "SOON",
        CLOSED: "CLOSED",
    },
    CENTER_OF_THE_WEEK: Math.floor(7 / 2),
    NUMBER_OF_DAYS_IN_A_WEEK: 7,
}

const getters = {
    STATUS(state) {
        return state.STATUS
    },
    CENTER_OF_THE_WEEK(state) {
        return state.CENTER_OF_THE_WEEK
    },
    NUMBER_OF_DAYS_IN_A_WEEK(state) {
        return state.NUMBER_OF_DAYS_IN_A_WEEK
    },
}

const mutations = {}

const actions = {
    [ACT_GET_ONAIR_BRC_LIST]({ }, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/dhfesta/onairBrc/list', params).then(response => resolve(response))
        })
    },
}

export const onair = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}