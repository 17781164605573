import ApiService from '../../assets/js/api.service';
import {
  ACT_GET_CAMPN_MANPRD_LIST,
  ACT_UPDATE_CAMPN_MANPRD_FAVR,
  ACT_UPDATE_CAMPN_MANPRD_STAR,
  ACT_GET_CAMPN_MY_MANPRD_LIST,
  ACT_GET_CAMPN_MANPRD_RAND_LIST,

} from '../_act_consts';

const state = {
}

const getters =  {
}

const mutations = {
}

const actions = {
  [ACT_GET_CAMPN_MANPRD_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/dhfesta/manprd/list', params).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_CAMPN_MANPRD_FAVR]({}, {manprdId, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/dhfesta/manprd/${manprdId}/favr`, params, true).then(response => resolve(response));
    });
  },
  [ACT_UPDATE_CAMPN_MANPRD_STAR]({}, {manprdId, params}) {
    return new Promise(resolve => {
      ApiService.put(`/v1/app/dhfesta/manprd/${manprdId}/star`, params, true).then(response => resolve(response));
    });
  },
  [ACT_GET_CAMPN_MY_MANPRD_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/dhfesta/manprd/my/list', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CAMPN_MANPRD_RAND_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/dhfesta/manprd/rand/list', params).then(response => resolve(response));
    });
  },
}

export const manprd = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
