import {ACT_GET_NOTICE2, ACT_GET_NOTICE2_LIST, ACT_DOWNLOAD_NOTICE2, ACT_DOWN_ATTACH_NOTICE2} from '../_act_consts';
import ApiService from '../../assets/js/api.service';
import axios from 'axios';

const actions = {
  [ACT_GET_NOTICE2_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/notice2/ntcs', params).then(response => resolve(response));
    });
  },
  [ACT_GET_NOTICE2]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/notice2/ntc', params).then(response => resolve(response));
    });
  },
  [ACT_DOWNLOAD_NOTICE2]({}, {ntcId, fileName}){
    return new Promise(resolve => {
        ApiService.download(`/v1/app/notice2/ntc`, 'atch',
            {ntcId}, fileName);
    });
  },
  [ACT_DOWN_ATTACH_NOTICE2]({}, {ntcId, fileName}){
    let url = '/v1/app/notice2/dnatch';
    const params = { ntcId }
    if(params !== null && params !== undefined){
        const keys = Object.keys(params);
        if(keys.length > 0){
            url += ('?'+ keys.map(key => `${key}=${params[key]}`).join('&'));
        }
    }
    return new Promise((resolve, reject) => {
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        }).then(res => { resolve(res) } , error => { reject(error) })
    })
  }
}

export const notice2 = {
  namespaced: true,
  actions
}
