import ApiService from '../../assets/js/api.service';
import {
  ACT_GET_NEWS_LIST,
  ACT_GET_NEWS_INFO,
} from '../_act_consts';

const state = {
}

const getters =  {
}

const mutations = {
}

const actions = {
  [ACT_GET_NEWS_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/dhfesta/news/list', params).then(response => resolve(response));
    });
  },
  [ACT_GET_NEWS_INFO]({}, params) {
    return new Promise(resolve => {
      ApiService.query(`/v1/app/dhfesta/news/detail`, params, true).then(response => resolve(response));
    });
  },
}

export const news = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
