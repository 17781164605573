<template>
  <div class="layer-popup-container" v-show="address.show">
    <!-- [layer-popup-mini:type1] -->
    <div class="layer-popup" :class="{'gachi-mobile-popup': isMobile, 'layer-md-popup': !isMobile}" v-if="address.show" :style="address.styleObj">
      <div class="popup-wrapper pb-10">
        <header class="popup-header">
          <h3 class="header-title">주소 검색</h3>
          <button type="button" class="btn-close" @click="clickCancel">
            <i class="icon-close">팝업닫기</i>
          </button>
        </header>
        <div class="popup-body" style="margin-bottom: 20px">
          <daum-postcode :on-complete="selectAddress" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DaumPostcode from 'vuejs-daum-postcode'
import {mapGetters} from 'vuex';

export default {
  name: 'AddressModal',
  components: {
    DaumPostcode
  },
  props: {},
  computed: {
    ...mapGetters('common', ['isMobile', 'address'])
  },
  mounted() {},
  methods: {
    clickCancel(){
      if(this.address.cancelFunc instanceof Function) this.address.cancelFunc();
      this.address.show = false;
    },
    // 다음 주소 검색용...
    selectAddress(a) {
      if(a){
        if(this.address.okFunc instanceof Function) this.address.okFunc(a);
      }
      this.address.show = false;
    },

  }
}
</script>
