import ApiService from '../../assets/js/api.service';
import {
    ACT_GET_TMPEXB_LIST
} from '../_act_consts';

const state = {
}

const getters =  {
}

const mutations = {
}

const actions = {
  [ACT_GET_TMPEXB_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/dhfesta/wnnTmpexb/items', params).then(response => resolve(response));
    });
  },
}

export const exhibition = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
