const mobileUtils = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry|BB10/i);
  },
  iOS: function() {
    // 2020-02-17 : hs.kim
    // iPad OS 13 이상 detect 문제
    // https://stackoverflow.com/questions/58019463/how-to-detect-device-name-in-safari-on-ios-13-while-it-doesnt-show-the-correct
    // Exclude IE11 UserAgent with window.MSStream
    return (navigator.userAgent.match(/iPhone|iPad|iPod/i) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream;
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function() {
    return (this.Android() || this.BlackBerry() ||
        this.iOS() || this.Opera() || this.Windows());
  }
}

export default mobileUtils;