import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import NProgress from 'vue-nprogress';
import Legacy_CKEditor from 'ckeditor4-vue/dist/legacy.js';
import ApiService from './assets/js/api.service';
import VueFriendlyIframe from 'vue-friendly-iframe';
import VueCookies from 'vue-cookies';

import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

import ConnectPlugin from './assets/js/connectPlugin';

import VueGtag from 'vue-gtag';

Vue.use(ConnectPlugin);

import AOS from 'aos';
import 'aos/dist/aos.css';
Vue.use(AOS);

const options = {
	latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
	router: true, // Show progressbar when navigating routes, default: true
	http: false, // Show progressbar when doing Vue.http, default: true
};
Vue.use(NProgress, options);

Vue.use(VueAwesomeSwiper);
Vue.use(Legacy_CKEditor);
Vue.use(VueFriendlyIframe);
Vue.use(VueCookies);

Vue.use(VueGtag, {
	config: {
		id: 'G-G79VWL50X6'  // Google Analytics의 Tracking ID for 동행축제
	}
}, router);

ApiService.init();
const nprogress = new NProgress();

Vue.config.productionTip = false;

const vm = new Vue({
	nprogress,
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');

window.app = vm;

