import Vue from 'vue'
import Vuex from 'vuex'
import { common } from './modules/common'
import { auth } from './modules/auth'
import { main } from './modules/main'
import {notice} from './modules/notice';
import {notice2} from './modules/notice2';
import {join} from './modules/join'; // 리뉴얼 회원가입
import {exhibition} from './modules/exhibition';
import {manprd} from './modules/manprd';
import {information} from './modules/information';
import {onair} from './modules/onair';
import {festival} from './modules/festival';
import {event} from './modules/event';
import {news} from './modules/news';
import {privacy} from './modules/privacy';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    auth,
    main,
    notice,
    notice2,
    exhibition,
    information,
    // video,
    // vod,
    join,
    manprd,
    onair,
    festival,
    event,
    news,
    privacy,
  }
})
