import ApiService from '../../assets/js/api.service';
import {
    ACT_GET_CAMPN_INFO_LIST
    , ACT_GET_CAMPN_GLRY_LIST
    , ACT_SET_PRVC_MKT_AGRC
} from '../_act_consts';

const state = {
}

const getters =  {
}

const mutations = {
}

const actions = {
  [ACT_GET_CAMPN_INFO_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/dhfesta/campn/lst', params).then(response => resolve(response));
    });
  },
  [ACT_GET_CAMPN_GLRY_LIST]({}, params){
    return new Promise(resolve => {
      ApiService.query('/v1/app/dhfesta/campn/glry/lst', params).then(response => resolve(response));
    });
  },
  [ACT_SET_PRVC_MKT_AGRC]({}, params){
    return new Promise(resolve => {
      ApiService.post('/v1/app/dhfesta/campn/prvcMktAgrc', params).then(response => resolve(response));
    });
  },
  
}

export const information = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
