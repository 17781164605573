<template>
	<svg><use :href="'/sprite.svg#' + name" /></svg>
</template>
<script>
export default {
	name: 'Icon',
	props: { name: null },
	components: {},
	computed: {},
	data: () => ({}),
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {},
	destroyed() {},
};
</script>
