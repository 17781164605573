const ID_TOKEN_KEY = "auth_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
  // return window.sessionStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  // window.sessionStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  // window.sessionStorage.removeItem(ID_TOKEN_KEY);
};

export const getTokenByKey = (key) => {
  return window.localStorage.getItem(key);
};

export const saveTokenByKey = (key ,token) => {
  window.localStorage.setItem(key, token);
};

export const destroyTokenByKey = (key) => {
  window.localStorage.removeItem(key);
  // window.sessionStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, getTokenByKey, saveTokenByKey, destroyTokenByKey };
