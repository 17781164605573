<template>
	<ul class="gachi-skipnav">
		<li class="skipnav-item"><a href="#container">메인 바로가기</a></li>
	</ul>
</template>

<script>
export default {
	name: 'SkipNav',
};
</script>
