<template>
	<router-view />
</template>
<script>
import navigationUtils from './assets/js/navigationUtils';
const mobileCheck = !!navigationUtils.any();

import('./assets/scss/ckeditor4/image.scss');
import('./assets/scss/ckeditor4/easyimage.scss');
import('./assets/scss/ckeditor4/slider.scss');

import('./assets/scss/style.scss');

export default {
	name: 'App',
	beforeCreate() {
		const isChrome = !!window.chrome && !!window.chrome.webstore,
			isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0,
			isFirefox = typeof InstallTrigger !== 'undefined',
			// isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)),
			// isIE = /*@cc_on!@*/false || !!document.documentMode,
			isIE = /*@cc_on!@*/ !!document.documentMode,
			isEdge = !isIE && !!window.StyleMedia,
			isBlink = (isChrome || isOpera) && !!window.CSS;

		if (isChrome) {
			document.querySelector('html').classList.add('chrome');
		} else if (isOpera) {
			document.querySelector('opera').classList.add('opera');
		} else if (isIE) {
			document.querySelector('html').classList.add('ie');
		} else if (isEdge) {
			document.querySelector('html').classList.add('edge');
		} else if (isFirefox) {
			document.querySelector('html').classList.add('firefox');
		} else if (isBlink) {
			document.querySelector('html').classList.add('blink');
		}
	},
};
</script>
