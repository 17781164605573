import {
    ACT_GET_LMS_LIST,
    ACT_GET_SSO_USER_INFO,
} from "@/store/_act_consts";
import ApiService from "@/assets/js/api.service";

const state = {
    bzInfo: {
        brno: '', //사업자등록번호
        openDay: '', //개업일자
        ceoNm: '', //대표명
        compNm: '', //기업명
        isCompCheck: false
    },
    userNm: '',
}

const getters = {
    bzInfo: state => state.bzInfo,
    userNm: state => state.userNm
}

const mutations = {
    setBzInfo: (state, bzInfo) => state.bzInfo = bzInfo,
    setUserNm: (state, userNm) => state.userNm = userNm,
};

const actions = {
    updateBzInfo: ({commit}, bzInfo) => commit('setBzInfo', bzInfo),
    updateUserNm: ({commit}, userNm) => commit('setUserNm', userNm),
    [ACT_GET_SSO_USER_INFO]({}, params) {
        return new Promise(resolve => {
            ApiService.query('/v1/app/auth/sso', params).then(response => resolve(response));
        });
    },
}
export const join = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}