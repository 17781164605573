import Vue from 'vue';
import VueRouter from 'vue-router';
import mobileUtils from '../assets/js/navigationUtils';
import MainLayout from '../views/layout/MainLayout.vue';
import EngLayout from '../views/layout/EngLayout.vue';

import store from '../store';
import { ACT_CHECK_AUTH_RES, ACT_GET_CAMPN_INFO } from '../store/_act_consts';
import { MUT_SET_AUTH_RES, MUT_SET_CAMPN_INFO_ITEM } from '../store/_mut_consts';

Vue.use(VueRouter);

const isMobile = !!mobileUtils.any();

const routes = [
	// Fullpage 용 별도 레이아웃
	{
		path: '/',
		component: MainLayout,
		children: [
			// [DEV]퍼블 : 메인페이지 리뉴얼 Fullpage
			{
				path: '',
				name: 'Main',
				component: () => import('../views/pages/Main'),
			},
			{
				path: '/ko/info',
				name: 'Information',
				meta: {
					title: '동행축제 > 행사정보',
				},
				component: () => import('../views/pages/ko/information/Information'),
			},
			{
				path: '/ko/info/history',
				name: 'InformationHistory',
				meta: {
					title: '동행축제 > 행사연혁',
				},
				component: () => import('../views/pages/ko/information/InformationHistory'),
			},
			{
				path: '/ko/promotion',
				name: 'Promotion',
				meta: {
					title: '동행축제 > 홍보물',
				},
				component: () => import('../views/pages/ko/promotion/Promotion'),
			},
			{
				path: '/ko/exhibition',
				name: 'ExhibitionPublish',
				meta: {
					title: '상생할인전',
				},
				component: () => import('../views/pages/ko/exhibition/Exhibition'),
			},

			{
				path: '/ko/onair',
				name: 'OnAir',
				meta: {
					title: '온에어쇼핑',
				},
				component: () => import('../views/pages/ko/onair/Onair'),
			},
			{
				path: '/ko/products/popular',
				name: 'ProductsPopular',
				meta: {
					title: '동행제품 > 인기제품',
				},
				component: () => import('../views/pages/ko/products/Popular'),
			},
			{
				path: '/ko/products/category',
				name: 'ProductsCategory',
				meta: {
					title: '동행제품 > 제품 카테고리',
				},
				component: () => import('../views/pages/ko/products/Category'),
			},
			{
				path: '/ko/festival',
				name: 'Festival',
				meta: {
					title: '지역행사',
				},
				component: () => import('../views/pages/ko/festival/Festival'),
			},
			{
				path: '/ko/event/check_publish',
				name: 'EventCheck',
				meta: {
					title: '이벤트 > 출석체크',
				},
				component: () => import('../views/pages/ko/event/EventCheck'),
			},
			{
				path: '/ko/event/dibs',
				name: 'EventDibs',
				meta: {
					title: '이벤트 > 찜하기',
				},
				component: () => import('../views/pages/ko/event/EventDibs'),
			},
			{
				path: '/ko/event/sns',
				name: 'EventSns',
				meta: {
					title: '이벤트 > 구매후기',
				},
				component: () => import('../views/pages/ko/event/EventSns'),
			},
			{
				path: '/ko/news/list',
				name: 'NewsList',
				meta: {
					title: '동행뉴스',
				},
				component: () => import('../views/pages/ko/news/NewsList'),
			},
			{
				path: '/ko/news/:newsId',
				name: 'NewsDetail',
				meta: {
					title: '동행뉴스',
				},
				component: () => import('../views/pages/ko/news/NewsDetail'),
			},
			{
				path: '/ko/mypage',
				name: 'Mypage',
				meta: {
					title: '마이페이지',
				},
				component: () => import('../views/pages/ko/mypage/Mypage'),
			},
			{
				path: '/ko/privacy-policy',
				name: 'PrivacyPolicy',
				meta: {
					title: '개인정보처리방침',
				},
				component: () => import('../views/pages/ko/terms/PrivacyPolicy'),
			},
			{
				path: '/ko/policy',
				name: 'Policy',
				meta: {
					title: '이용약관',
				},
				component: () => import('../views/pages/ko/terms/Policy'),
			},
			// 개발 페이지
			{
				path: '/ko/info/campnHistory',
				name: 'CampnHistory',
				meta: {
					title: '동행축제 > 행사연혁',
				},
				component: () => import('../views/pages/ko/information/CampnHistory'),
			},
			{
				path: '/ko/exhibitionList',
				name: 'Exhibition',
				meta: {
					title: '상생할인전',
				},
				component: () => import('../views/pages/ko/exhibition/ExhibitionList'),
			},
			{
				path: '/ko/event/check',
				name: 'EventAttendanceCheck',
				meta: {
					title: '이벤트 > 출석체크',
				},
				component: () => import('../views/pages/ko/event/AttendanceCheck'),
			},
			{
				path: '/ko/event/check1',
				name: 'EventAttendanceCheck',
				meta: {
					title: '이벤트 > 출석체크',
				},
				component: () => import('../views/pages/ko/event/EventCheck'),
			},
		],
	},
	{
		path: '/',
		component: () => import('../views/layout/LoginLayout'),
		children: [
			{
				path: 'login',
				name: 'Login',
				component: () => import('../views/pages/auth/Login'),
			},
			{
				path: 'signup',
				name: 'Signup',
				component: () => import('../views/pages/auth/Signup'),
			},
			{
				path: 'findInfo/:division',
				name: 'FindInfo',
				component: () => import('../views/pages/auth/FindInfo'),
				beforeEnter: (to, from, next) => {
					if (to.params.division !== 'id' && to.params.division !== 'password') {
						next({ name: 'Main' });
					}
					next();
				},
			},
		],
	},
	{
		path: '/',
		component: EngLayout,
		children: [
			{
				path: '/en',
				name: 'English Main',
				meta: {
					title: 'Korea Shopping Festa',
				},
				component: () => import('../views/pages/en/Main'),
			},
			{
				path: '/en/event',
				name: 'Event Guide',
				meta: {
					title: 'Korea Shopping Festa',
				},
				component: () => import('../views/pages/en/EventGuide'),
			},
			{
				path: '/en/products',
				name: 'Featured Products',
				meta: {
					title: 'Korea Shopping Festa',
				},
				component: () => import('../views/pages/en/Products'),
			},
		],
	},
];

function acecount() {
	var _AceGID = (function () {
		var Inf = ['gtp12.acecounter.com', '8080', 'AH6A45756391491', 'AW', '0', 'NaPm,Ncisy', 'ALL', '0'];
		var _CI = !_AceGID ? [] : _AceGID.val;
		var _N = 0;
		var _T = new Image(0, 0);
		if (_CI.join('.').indexOf(Inf[3]) < 0) {
			_T.src = 'https://' + Inf[0] + '/?cookie';
			_CI.push(Inf);
			_N = _CI.length;
		}
		return { o: _N, val: _CI };
	})();
	var _AceCounter = (function () {
		var G = _AceGID;
		var _sc = document.createElement('script');
		var _sm = document.getElementsByTagName('script')[0];
		if (G.o != 0) {
			var _A = G.val[G.o - 1];
			var _G = _A[0].substr(0, _A[0].indexOf('.'));
			var _C = _A[7] != '0' ? _A[2] : _A[3];
			var _U = _A[5].replace(/\,/g, '_');
			_sc.src = 'https:' + '//cr.acecounter.com/Web/AceCounter_' + _C + '.js?gc=' + _A[2] + '&py=' + _A[4] + '&gd=' + _G + '&gp=' + _A[1] + '&up=' + _U + '&rd=' + new Date().getTime();
			_sm.parentNode.insertBefore(_sc, _sm);
			return _sc.src;
		}
	})();
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		//페이지 뒤로가기, 앞으로가기시 스크롤 위치유지
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({ left: 0, top: 0 });
			}, 500);
		});

		// if (savedPosition) {
		// 	return savedPosition
		// } else {
		// 	return { x: 0, y: 0 }
		// }
	},
});

let tryCount = 0;

const getAuthRes = (next) => {
	if (tryCount > 10) {
		next();
		return;
	}
	store.dispatch(`auth/${ACT_CHECK_AUTH_RES}`).then((res) => {
		if (res === undefined) {
			tryCount++;
			setTimeout(() => {
				getAuthRes(next);
			}, 300);
		} else {
			store.commit(`auth/${MUT_SET_AUTH_RES}`);
			next();
		}
	});
};

let createCampnYn = false;
const createCampn = async () => {
	await store.dispatch(`common/${ACT_GET_CAMPN_INFO}`, { campnId: 0 }).then((res) => {
		if (res.items.length > 0) {
			createCampnYn = true;
			store.commit(`common/${MUT_SET_CAMPN_INFO_ITEM}`, res.items[0]);
		}
	});
};

router.beforeEach(async (to, from, next) => {
	setTimeout(() => {
		window.scrollTo(0, 0);
		acecount();
	}, 100);

	if (!createCampnYn || from.name == 'Login') await createCampn();

	// console.log(store.state.auth.resValidate);
	if (store.state.auth.resValidate) {
		next();
	} else {
		getAuthRes(next);
	}
});

const makeTitle = (title) => (title ? `${title}` : `동행축제`);

router.afterEach((to, from) => {
	Vue.nextTick(() => {
		document.title = makeTitle(to.meta.title);
	});
});

export default router;
